<template>
  <div class="tag-nav-com">
    <div class="tag-item cur-po flex al-i-c ju-c-c" :class="{'on':isCurrentTag(item)}"
         v-for="(item,index) in list" :key="index" @click="handleClick(item)">
      <span>{{showTitleInside(item)}}</span>
      <Icon @click.stop="close(item)" v-if="item.name !== $config.homeName" type="ios-close"/>
    </div>
  </div>
</template>

<script>
  import {showTitle, routeEqual} from '@/libs/util'

  export default {
    name: 'tag-nav',
    props: {
      value: Object,
      list: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data() {
      return {}
    },
    computed: {
      currentRouteObj() {
        const {name, params, query} = this.value
        return {name, params, query}
      }
    },
    methods: {
      showTitleInside(item) {
        return showTitle(item, this)
      },
      isCurrentTag(item) {
        return routeEqual(this.currentRouteObj, item)
      },
      close(route) {
        let res = this.list.filter(item => !routeEqual(route, item))
        this.$emit('on-close', res, undefined, route)
      },
      handleClick(item) {
        this.$emit('input', item)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .tag-nav-com {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 50px 34px 20px;

    .tag-item {
      width: 91px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 12px;
      color: #9a9bab;
      margin-right: 20px;
      background-image: url("../assets/images/tag-bg.png");
      background-size: 91px 30px;
      background-repeat: no-repeat;

      &.on {
        background-image: url("../assets/images/tag-bg-on.png");
        color: #fff;
      }
    }
  }
</style>
