import { loginApi, logoutApi, getUserInfoApi } from '@/apis/userApis'

import {
  setToken,
  getToken,
  localClear,
  localSave,
  localRead,
} from '@/libs/util'

export default {
  state: {
    userInfo: localRead('userInfo') || {},
    token: getToken(),
    hasGetInfo: false,
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setToken(state, token) {
      state.token = token
      setToken(token)
    },
    setHasGetInfo(state, status) {
      state.hasGetInfo = status
    },
  },
  getters: {
    _UserInfo: (state) => state.userInfo,
  },
  actions: {
    // 登录
    handleLogin({ commit }, params) {
      return new Promise((resolve, reject) => {
        loginApi(params)
          .then((res) => {
            const {
              orgName,
              phone,
              roleId,
              roleName,
              sexName,
              userName,
              userType,
              userTypeName,
            } = res.data
            commit('setToken', res.data.tokenId)
            commit('setUserInfo', {
              orgName,
              phone,
              roleId,
              roleName,
              sexName,
              userName,
              userType,
              userTypeName,
            })
            localSave('userInfo', {
              orgName,
              phone,
              roleId,
              roleName,
              sexName,
              userName,
              userType,
              userTypeName,
            })
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 退出登录
    handleLogOut({ state, commit }) {
      return new Promise((resolve, reject) => {
        logoutApi()
          .then((res) => {
            commit('setToken', '')
            localClear()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取用户相关菜单权限信息
    getUserInfo({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getUserInfoApi(params)
          .then((res) => {
            commit('setHasGetInfo', true)
            commit('setPermission', {
              permission: res.data,
            })
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
