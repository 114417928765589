import axios from '@/libs/api.request'
import { getToken } from '@/libs/util'

// 展厅管理
// 获取展厅列表数据
export const hallInfoDataApi = (params) => {
  return axios.request({
    url: 'hallInfo/list.do',
    params,
    method: 'get',
  })
}

// 新增展厅
export const addHallInfoApi = (data) => {
  return axios.request({
    url: 'hallInfo/add.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 修改展厅
export const editHallInfoApi = (data) => {
  return axios.request({
    url: 'hallInfo/edit.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 删除展厅
export const deleteHallInfoApi = (data) => {
  return axios.request({
    url: 'hallInfo/delete.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 展项管理
// 获取展项列表数据
export const hallOptionDataApi = (data) => {
  return axios.request({
    url: 'hallOption/list.do',
    data,
    method: 'post',
  })
}

// 新增展项
export const addHallOptionApi = (data) => {
  return axios.request({
    headers: {
      'Content-Type': 'application/json',
      tokenId: getToken(),
    },
    url: 'hallOption/add.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 修改展项
export const editHallOptionApi = (data) => {
  return axios.request({
    headers: {
      'Content-Type': 'application/json',
      tokenId: getToken(),
    },
    url: 'hallOption/edit.do',
    data,
    method: 'post',
    showMsg: true,
  })
}

// 删除展项
export const deleteHallOptionApi = (data) => {
  return axios.request({
    url: 'hallOption/delete.do',
    data,
    method: 'post',
    showMsg: true,
  })
}
// 展厅详情
export const detailHallApi = (data) => {
  return axios.request({
    url: 'hallInfo/detail.do',
    data,
    method: 'post',
    showMsg: true,
  })
}
// 展项下线日志
export const optionLogList = (data) => {
  return axios.request({
    url: 'optionLog/list.do',
    data,
    method: 'post',
  })
}
// 展项下线日志已阅读
export const optionLogEdit = (data) => {
  return axios.request({
    url: 'optionLog/edit.do',
    data,
    method: 'post',
  })
}
