import axios from "@/libs/api.request";

// 登录
export const loginApi = (data) => {
  return axios.request({
    url: "admin/login.do",
    data,
    method: "post",
  });
};

// 登出
export const logoutApi = (data) => {
  return axios.request({
    url: "admin/exits.do",
    data,
    method: "post",
  });
};

// 修改密码
export const editPassword = (data) => {
  return axios.request({
    url: "admin/pwd.do",
    data,
    method: "post",
    showMsg: true,
  });
};

// 获取角色对应的菜单权限
export const getUserInfoApi = (data) => {
  return axios.request({
    url: "role/findByRoleId.do",
    data,
    method: "post",
  });
};
