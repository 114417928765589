import axios from "axios";
import qs from "qs";
import store from "@/store";
import { Message, LoadingBar } from "view-design";
import {
  getToken,
  // getBrowser,
  // getOs
} from "@/libs/util";

// const addErrorLog = errorInfo => {
//   const {
//     statusText,
//     status,
//     request: {
//       responseURL
//     }
//   } = errorInfo
//   let info = {
//     type: 'ajax',
//     code: status,
//     osInfo: getOs(),
//     browserInfo: getBrowser().browser + '/' + getBrowser().version,
//     mes: errorInfo.data.message ? errorInfo.data.message : statusText,
//     url: responseURL
//   }
//   if (!responseURL.includes('save_error_logger')) {
//     store.dispatch('addErrorLog', info)
//   }
// }

class HttpRequest {
  path = "";
  curPath = "";

  constructor(baseUrl = baseURL) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }

  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        tokenId: getToken(),
      },
    };
    return config;
  }

  destroy(url) {
    delete this.queue[url];
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }

  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        // if (process.env.VUE_APP_TYPE === 'app') {
        //   config.baseURL = store.getters.baseUrl
        // }
        this.queue[url] = true;
        LoadingBar.start();
        // 本项目请求传参方式为formdata，如果为json，删除下面这一句
        if (config.headers["Content-Type"] !== "application/json") {
          config.data = qs.stringify(config.data);
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      (response) => {
        this.destroy(url);
        if (response.data.code === 0) {
          LoadingBar.finish();
          if (response.config.showMsg) {
            Message.success({
              duration: 5,
              content: response.data.msg,
            });
          }
          return response.data;
        } else if (response.data.code === 1001) {
          // 需要登录了，回到登录页面
          LoadingBar.error();
          return response.data;
        } else {
          LoadingBar.error();
          Message.error({
            duration: 5,
            closable: true,
            content: response.data.msg,
          });
          return Promise.reject(response.data);
        }
      },
      (error) => {
        this.destroy(url);
        let errorInfo = error.response;
        if (!errorInfo) {
          const {
            request: { statusText, status },
            config,
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            statusText,
            status,
            request: {
              responseURL: config.url,
            },
          };
        }
        // addErrorLog(errorInfo)
        LoadingBar.error();
        return Promise.reject(error);
      }
    );
  }

  setPath(...paths) {
    this.curPath = `${this.path}/${paths.join("/")}`;
    return this;
  }

  replace(...params) {
    let count = 0;
    this.curPath = this.curPath.replace(
      /\{.*?\}/g,
      (_match) => params[count++]
    );
    return this;
  }

  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    if (this.curPath !== "") {
      options.url = this.curPath;
      this.curPath = "";
      this.path = "";
    }
    this.interceptors(instance, options.url);
    return instance(options);
  }
}

export default HttpRequest;
