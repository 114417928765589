<template>
  <Drawer :title="title" class-name="my-drawer" v-model="showDrawer" :width="width" :mask-closable="maskClosable" @on-visible-change="change">
    <div class="border-line-box">
      <span class="border-line line-1"></span>
      <span class="border-line line-2"></span>
      <span class="border-line line-3"></span>
      <span class="border-line line-4"></span>
    </div>
    <div class="title-box">{{title}}</div>
    <div class="body-box custom-scrollbar">
      <slot name="body"></slot>
    </div>
  </Drawer>
</template>

<script>
export default {
  name: 'myDrawerCom',
  props: {
    title: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 720,
    },
    maskClosable: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    infoData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      showDrawer: false,
    }
  },
  methods: {
    change(data) {
      this.$emit('change', data)
    },
    show() {
      this.showDrawer = true
    },
    hide() {
      this.showDrawer = false
    },
  },
}
</script>

<style lang="scss" scoped>
// 重置iview某些组件的样式
/deep/.my-drawer {
  .ivu-drawer {
    color: #00eff1;
    .ivu-drawer-close {
      top: 20px;
      right: 40px;
      z-index: 11;
      .ivu-icon {
        color: #00eff1;
      }
    }
    .ivu-drawer-content {
      background-color: rgba($color: #000, $alpha: 0.4);
      .ivu-drawer-header {
        display: none;
      }
      .ivu-drawer-body {
        height: 100%;
        position: relative;
        .title-box {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 60px;
          line-height: 40px;
          font-size: 16px;
          font-weight: bold;
          padding: 10px;
          background-color: rgba($color: #000, $alpha: 0.7);
          z-index: 10;
        }
        .body-box {
          padding-top: 60px;
          height: 100%;
          overflow-y: auto;
        }
        .border-line-box {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: hidden;
          z-index: -1;
        }
        .border-line {
          position: absolute;
          display: block;
        }
        .line-1 {
          top: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: linear-gradient(90deg, transparent, #03e9f4);
          animation: animate1 3s linear infinite;
        }
        .line-2 {
          top: -100%;
          right: 0;
          width: 2px;
          height: 100%;
          background: linear-gradient(180deg, transparent, #03e9f4);
          animation: animate2 3s linear infinite;
          animation-delay: 0.25s;
        }
        .line-3 {
          bottom: 0;
          right: 0;
          width: 100%;
          height: 2px;
          background: linear-gradient(270deg, transparent, #03e9f4);
          animation: animate3 3s linear infinite;
          animation-delay: 0.5s;
        }
        .line-4 {
          bottom: -100%;
          left: 0;
          width: 2px;
          height: 100%;
          background: linear-gradient(360deg, transparent, #03e9f4);
          animation: animate4 3s linear infinite;
          animation-delay: 0.75s;
        }
      }
    }
  }
}
</style>