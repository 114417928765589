import Vue from 'vue'
import { mapGetters } from 'vuex'
import App from './App.vue'
import router from './router/index'
import store from './store'
import config from '@/config'
import storage from '@/libs/storageUtil'
import * as echarts from 'echarts'
import ECharts from 'vue-echarts'

import videojs from 'video.js'
import '@videojs/http-streaming'
import 'videojs-flvjs-es6'
import 'amfe-flexible'
import 'reset-css'
import ViewUI from 'view-design'
import 'video.js/dist/video-js.css'
import 'view-design/dist/styles/iview.css'
import '@/assets/style/common.scss'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
const storeLs = require('store')

// if (process.env.NODE_ENV !== 'production') require('@/mock')
require('@/mock')

Vue.use(ViewUI)

Vue.component('VueEcharts', ECharts)
Vue.component('vue-draggable-resizable', VueDraggableResizable)

Vue.config.productionTip = false
/**
 * @description 全局注册应用配置
 */
Vue.prototype.$config = config
Vue.prototype.$echarts = echarts
Vue.prototype.$storage = storage
Vue.prototype.$videojs = videojs
Vue.prototype.$storeLs = storeLs

Vue.prototype.$px2spx = (px) => {
  const base = 192
  const clientWidth = document.documentElement.clientWidth / 10
  const ratio = clientWidth / base
  return px * ratio
}
//
Vue.mixin({
  computed: {
    ...mapGetters(['_UserInfo']),
  },
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
