import {
  getBreadCrumbList,
  setTagNavListInLocalstorage,
  getMenuByRouter,
  getTagNavListFromLocalstorage,
  getHomeRoute,
  getNextRoute,
  routeHasExist,
  routeEqual,
  getRouteTitleHandled,
  localSave,
  localRead,
  formatRouter,
  sortingArray
} from '@/libs/util'
// import { saveAdminErrorApi } from '@/apis/user'
import router from '@/router'
import routers from '@/router/routers'
// import menus from '@/router/menus'
import config from '@/config'

const { homeName } = config

const closePage = (state, route) => {
  const nextRoute = getNextRoute(state.tagNavList, route)
  state.tagNavList = state.tagNavList.filter(item => {
    return !routeEqual(item, route)
  })
  router.push(nextRoute)
}

const cloneMenu = function (newMenus, { path, name, meta, component, children }) {
  let obj = { path, name, meta, component }
  newMenus.push(obj)
  if (children && children.forEach) {
    obj.children = []
    children.forEach(function (child) {
      cloneMenu(obj.children, child)
    })
  }
}

const cloneMenus = function (menus) {
  let newMenus = []
  menus.forEach(function (menu) {
    cloneMenu(newMenus, menu)
  })
  return newMenus
}

const filterMenu = function (menu, targets) {
  if (menu.children) {
    for (let i = 0; i < menu.children.length; i++) {
      let remain = filterMenu(menu.children[i], targets)
      if (remain === false) {
        menu.children.splice(i, 1)
        i--
      }
    }
    if (menu.children.length === 0) {
      return false
    }
  } else if (!targets || targets.indexOf(menu.name) === -1) {
    return false
  }
}
const filterMenus = function (menus, targets) {
  for (let i = 0; i < menus.length; i++) {
    let remain = filterMenu(menus[i], targets)
    if (remain === false) {
      menus.splice(i, 1)
      i--
    }
  }
  return menus
}

export default {
  state: {
    breadCrumbList: [],
    tagNavList: [],
    homeRoute: {},
    local: localRead('local'),
    permission: routers,
    screenType: 'big'
  },
  getters: {
    getMenuList: (state, getters, rootState) => getMenuByRouter(state.permission, []),
    screenType: state => state.screenType,
    getPermission: state => state.permission
  },
  mutations: {
    setScreenType(state, screenWidth) {
      if (screenWidth > 750) {
        state.screenType = 'big'
        document.getElementsByTagName('html')[0].classList = ['pc']
      } else {
        state.screenType = 'small'
        document.getElementsByTagName('html')[0].classList = ['mobile']
      }
    },
    //设置路由权限（即该用户可访问到的页面菜单）
    setPermission(state, { permission }) {
      //根据排序字段进行菜单排序
      // sortingArray(permission, 'sort', true)
      let routerInfo = [{
        path: '/',
        name: '_home',
        redirect: '/home',
        component: null,
        children: [...permission]
      }]
      // localSave('routers', routerInfo)
      let formatDynamicRouter = formatRouter(routerInfo)
      formatDynamicRouter.push({
        path: '*',
        name: 'error_404',
        meta: {
          hideInMenu: true
        },
        component: () => import('@/views/ErrorPage/404.vue')
      })
      state.permission = formatDynamicRouter
    },
    setBreadCrumb(state, route) {
      state.breadCrumbList = getBreadCrumbList(route, state.homeRoute)
    },
    setHomeRoute(state, routes) {
      state.homeRoute = getHomeRoute(routes, homeName)
    },
    setTagNavList(state, list) {
      let tagList = []
      if (list) {
        tagList = [...list]
      } else {
        tagList = getTagNavListFromLocalstorage() || []
      }
      if (tagList[0] && tagList[0].name !== homeName) tagList.shift()
      let homeTagIndex = tagList.findIndex(item => item.name === homeName)
      if (homeTagIndex > 0) {
        let homeTag = tagList.splice(homeTagIndex, 1)[0]
        tagList.unshift(homeTag)
      }
      state.tagNavList = tagList
      setTagNavListInLocalstorage([...tagList])
    },
    closeTag(state, route) {
      let tag = state.tagNavList.filter(item => routeEqual(item, route))
      route = tag[0] ? tag[0] : null
      if (!route) return
      closePage(state, route)
    },
    addTag(state, { route, type = 'unshift' }) {
      let router = getRouteTitleHandled(route)
      if (!routeHasExist(state.tagNavList, router)) {
        if (type === 'push') {
          state.tagNavList.push(router)
        } else {
          if (router.name === homeName) {
            state.tagNavList.unshift(router)
          } else {
            state.tagNavList.splice(1, 0, router)
          }
        }
        setTagNavListInLocalstorage([...state.tagNavList])
      }
    },
    setLocal(state, lang) {
      localSave('local', lang)
      state.local = lang
    },
  },
  actions: {
    // addErrorLog ({ commit, rootState }, info) {
    //   if (!window.location.href.includes('error_logger_page')) {
    //     commit('setHasReadErrorLoggerStatus', false)
    //   }
    //   saveAdminErrorApi(info).then(() => {
    //     commit('addError', info)
    //   })
    // }
  }
}
