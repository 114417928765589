import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routers'
import store from '@/store'
import config from '@/config'
import { LoadingBar } from 'view-design'
import {
  setTitle,
  getToken,
  localRead,
  localClear,
  formatRouter,
} from '@/libs/util'

Vue.use(VueRouter)

VueRouter.prototype.replace = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const originalReplace = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  routes: routes,
  mode: 'hash',
})

const LOGIN_PAGE_NAME = 'login'
router.beforeEach((to, from, next) => {
  const token = getToken()
  if (!token && to.name !== LOGIN_PAGE_NAME) {
    // 未登录且要跳转的页面不是登录页
    next({
      name: LOGIN_PAGE_NAME, // 跳转到登录页
    })
  } else if (!token && to.name === LOGIN_PAGE_NAME) {
    // 未登陆且要跳转的页面是登录页
    // 跳转
    next()
  } else if (token && to.name === LOGIN_PAGE_NAME) {
    // 已登录且要跳转的页面是登录页
    next({
      name: config.homeName,
    })
  } else {
    if (store.state.user.hasGetInfo) {
      next()
    } else {
      if (localRead('userInfo')) {
        store
          .dispatch('getUserInfo', {
            roleId: localRead('userInfo').roleId,
          })
          .then((res) => {
            router.addRoutes(store.state.app.permission)
            next({
              ...to,
              replace: true,
            })
          })
          .catch((err) => {
            localClear()
            next({
              name: LOGIN_PAGE_NAME, // 跳转到登录页
            })
          })
      } else {
        localClear()
        next({
          name: LOGIN_PAGE_NAME, // 跳转到登录页
        })
      }
    }
  }
})

router.afterEach((to) => {
  setTitle(to, router.app)
  LoadingBar.finish()
  window.scrollTo(0, 0)
})

export default router
