<template>
  <div class="zebra-marking-com" :class="direction" :style="{height: $px2spx(height)+'px'}"></div>
</template>

<script>
  export default {
    name: 'zebra-marking',
    props: {
      direction: {
        type: String,
        default: 'to-right'
      },
      height: {
        type: Number,
        default: () => {
          return 20
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .zebra-marking-com {
    height: 20px;
    position: relative;

    &.to-right {
      background-image: linear-gradient(to right, #00DADE, rgba(0, 218, 222, 0));
    }

    &.to-left {
      background-image: linear-gradient(to left, #00DADE, rgba(0, 218, 222, 0));
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(-45deg, #020826 0%, #020826 25%, transparent 25%, transparent 50%, #020826 50%, #020826 75%, transparent 75%, transparent 100%);
      background-size: 5px 5px; //默认background-repeat为repeat
      z-index: 1;
    }
  }
</style>
