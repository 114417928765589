<template>
  <div class="layout-main page-view">
    <Layout>
      <Header class="header flex ju-c-sb">
        <div class="left-view flex">
          <img src="../assets/images/logo.png" class="logo-img" alt="">
          <div class="menu-view">
            <Menu ref="mainMenu" mode="horizontal" @on-select="menuSelect" :open-names="openNames" :active-name="activeName">
              <template v-for="(menuItem,menuIndex) in menuList">
                <MenuItem v-if="!menuItem.hasOwnProperty('children')" :key="menuIndex" :name="menuItem.name">
                <div class="menu-item">
                  <div class="menu-title">{{menuItem.meta.title}}</div>
                  <div class="menu-circle">
                    <div class="bottom"></div>
                    <div class="h-box"></div>
                  </div>
                </div>
                </MenuItem>
                <Submenu v-else :name="menuItem.name" :key="menuIndex">
                  <template slot="title">
                    <div class="menu-item">
                      <div class="menu-title">{{menuItem.meta.title}}</div>
                      <div class="menu-circle">
                        <div class="bottom"></div>
                        <div class="h-box"></div>
                      </div>
                    </div>
                  </template>
                  <MenuItem v-for="(menuOitem,menuOindex) in menuItem.children" :key="menuOindex" :name="menuOitem.name">{{menuOitem.meta.title}}</MenuItem>
                </Submenu>
              </template>
            </Menu>
          </div>
        </div>
        <div class="user-info-view flex al-i-c">
          <div class="iconfont anim m-l-10 m-r-10" :class="!isFullscreen?'icon-quanping':'icon-tuichuquanping'" @click="handleScreenfull"  style="font-size: 22px"></div>
          <div class="m-l-10 m-r-10"  style="cursor: pointer">
            <Tooltip placement="bottom-end" @on-popper-hide="aleadyLog"  max-width="300">
              <Badge :count="lstLogSize" :offset="[10,0]">
                <div class="iconfont icon-xiaoxi" style="font-size: 22px"> </div>
              </Badge>
              <template #content>
                <div style="max-height: 200px;overflow: scroll">
                  <p v-if="lstLogSize>0" v-for="item in lstLog">{{item.createdDate}} <span style="margin-left: 10px">{{item.optionName}} 下线了！</span></p>
                  <p  v-if="lstLogSize==0">无</p>
                </div>
              </template>
            </Tooltip>
          </div>
          <div class="user-info flex al-i-c m-l-10 m-r-10">
            <Avatar class="user-avatar" src="https://i.loli.net/2017/08/21/599a521472424.jpg" />
            <Dropdown @on-click="userHandle">
              <div class="user-name">
                <span class="m-l-10 m-r-2 fz-16">{{_UserInfo.userName}}</span>
                <Icon class="arrow-down" type="md-arrow-dropdown" />
              </div>
              <DropdownMenu slot="list">
                <DropdownItem name="editPassword">修改密码</DropdownItem>
              </DropdownMenu>
              <DropdownMenu slot="list">
                <DropdownItem name="logout">退出登陆</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </Header>
      <tag-nav v-if="$route.name!=='home' && $route.name!=='dataAnalyse' " :value="$route" :list="tagNavList" @input="handleClick" @on-close="handleCloseTag" />
      <Content class="content custom-scrollbar" :class="{'show-tag':$route.name!=='home'  && $route.name!=='dataAnalyse'}">
        <div class="content-box">
          <keep-alive>
            <router-view ref="viewArea" />
          </keep-alive>
        </div>
      </Content>
    </Layout>
    <div class="pop-up-box flex al-i-c ju-c-c" v-if="isShowPopup">
      <div class="pop-up-content">
        <div class="iconfont icon-guanbi popup-close-btn cur-po" @click="hidePopup"></div>
        <div class="title-box flex al-i-c ju-c-sb">
          <span class="title">选择展厅</span>
          <div class="zebra-box">
            <zebra-marking direction="to-right" :height="14" />
          </div>
        </div>
        <div class="popup-search-box flex al-i-c ju-c-sb">
          <input class="popup-search-input" placeholder="请输入搜索展示" v-model="keyword" type="text" />
          <i @click="searchExhibition" class="iconfont icon-sousuo" />
        </div>
        <div class="results-list-box">
          <div class="results-list custom-scrollbar">
            <div class="results-item flex al-i-c ju-c-sb" v-for="(item,index) in resultsList" :key="index">
              <div class="results-title">{{item.hallName}}</div>
              <div class="to-btn" @click="toExhibitionFun(item)">进入</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <my-drawer-com title="修改密码" ref="myDrawerCom" :width="$px2spx(600)">
      <div slot="body">
        <div class="my-from-box m-t-20">
          <Form ref="editPasswordForm" :model="editPasswordForm" :rules="editPasswordFormRules" :label-width="$px2spx(100)" label-position="left">
            <FormItem label="旧密码" prop="oldPassword">
              <Input v-model="editPasswordForm.oldPassword" placeholder="请输入旧密码"></Input>
            </FormItem>
            <FormItem label="新密码" prop="password">
              <Input type="text" v-model="editPasswordForm.password" placeholder="请输入新密码"></Input>
            </FormItem>
            <FormItem label="确认密码" prop="confirmpassword">
              <Input type="text" v-model="editPasswordForm.confirmpassword" placeholder="请再次输入新密码"></Input>
            </FormItem>
            <FormItem label="">
              <div class="submit-btn" @click="submitEditPassword">提交</div>
            </FormItem>
          </Form>
        </div>
      </div>
    </my-drawer-com>
  </div>
</template>

<script>
import { hallInfoDataApi,optionLogList,optionLogEdit } from '../apis/exhibitionManagementApis'
import { mapMutations } from 'vuex'
import { getNewTagList, routeEqual } from '@/libs/util'
import { searchExhibitionApi } from '@/apis/searchApis'
import { editPassword } from '@/apis/userApis'
import screenfull from 'screenfull'
import routers from '@/router/routers'
import tagNav from '@/components/tag-nav'
import myDrawerCom from '@/components/my-drawer-com.vue'
import zebraMarking from '@/components/zebra-marking'
import dateFormat from 'dateformat'
export default {
  name: 'layout-main',
  components: {
    tagNav,
    myDrawerCom,
    zebraMarking,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.editPasswordForm.confirmpassword !== '') {
          // 对第二个密码框单独验证
          this.$refs.editPasswordForm.validateField('confirmpassword')
        }
        callback()
      }
    }
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.editPasswordForm.password) {
        callback(new Error('两次输入的密码不匹配'))
      } else {
        callback()
      }
    }
    return {
      lstLogSize:0,
      lstLog:[],
      isFullscreen: false,
      isShowPopup: false,
      isShowPopupPages: ['remoteControl', 'dataAnalyse', 'realTimePlaying'], //后续根据权限管理功能中的菜单管理进行配置哪些菜单需要打开弹出层
      resultsList: [],
      activeName: 'home',
      oldMenuName: 'home',
      openNames: [],
      keyword: '',
      subMitIng: false,
      editPasswordForm: {
        oldPassword: '',
        password: '',
        confirmpassword: '',
      },
      editPasswordFormRules: {
        oldPassword: [
          {
            required: true,
            message: '请输入旧密码',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入新密码',
            trigger: 'blur',
          },
          {
            validator: validatePass,
            trigger: 'blur',
          },
        ],
        confirmpassword: [
          {
            required: true,
            message: '请再次输入新密码',
            trigger: 'blur',
          },
          {
            validator: validatePassCheck,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    tagNavList() {
      return this.$store.state.app.tagNavList
    },
    menuList() {
      return this.$store.getters.getMenuList[0].children
    },
  },
  methods: {
    ...mapMutations(['setTagNavList', 'addTag', 'setHomeRoute', 'closeTag']),
    aleadyLog(){
      optionLogEdit()
    },
    handleScreenfull() {
      if (!screenfull.isEnabled) {
        this.$Message.warning('您的浏览器不支持全屏')
        return
      }
      screenfull.toggle()
      this.isFullscreen = !this.isFullscreen
    },
    turnToPage(route) {
      let { name, params, query } = {}
      if (typeof route === 'string') {
        name = route
      } else {
        name = route.name
        params = route.params
        query = route.query
      }
      if (name.indexOf('isTurnByHref_') > -1) {
        window.open(name.split('_')[1])
        return
      }
      this.$router.push({
        name,
        params,
        query,
      })
    },
    handleCloseTag(res, type, route) {
      if (type !== 'others') {
        if (type === 'all') {
          this.turnToPage(this.$config.homeName)
        } else {
          if (routeEqual(this.$route, route)) {
            this.closeTag(route)
          }
        }
      }
      this.setTagNavList(res)
    },
    handleClick(item) {
      this.turnToPage(item)
    },
    menuSelect(name) {
      if (this.isShowPopupPages.indexOf(name) > -1) {
        //执行弹出选择框
        this.oldMenuName = this.activeName
        this.activeName = name
        this.showPopup()
      } else {
        this.$router.push({
          name: name,
        })
      }
    },
    async searchExhibition() {
      // let res = await searchExhibitionApi({
      //   keyword: this.keyword,
      // })
      let res = await hallInfoDataApi({
        hallName: this.keyword,
      })
      this.resultsList = res.data
    },
    toExhibitionFun(item) {
      this.$router.push({
        name: this.activeName,
        params: {
          id: item.rowId,
          hallName: item.hallName,
        },
      })
      this.isShowPopup = false
    },
    showPopup() {
      this.isShowPopup = true
      this.searchExhibition()
    },
    hidePopup() {
      this.isShowPopup = false
      this.activeName = this.oldMenuName
      this.openNames = []
      this.$nextTick(() => {
        console.log('activeName:', this.activeName)
        this.$refs.mainMenu.updateActiveName()
        this.$refs.mainMenu.updateOpened()
      })
    },
    // 用户头像处下拉菜单操作
    async userHandle(name) {
      switch (name) {
        case 'editPassword':
          this.$refs.myDrawerCom.show()
          break
        case 'logout':
          await this.$store.dispatch('handleLogOut')
          this.$router.push({
            name: 'login',
            redirect: true,
          })
          window.location.reload()
          break
        default:
          break
      }
    },
    // 提交修改密码
    submitEditPassword() {
      if (!this.subMitIng) {
        this.subMitIng = true
        this.$refs['editPasswordForm'].validate(async (valid) => {
          if (valid) {
            try {
              let editPsdRes = await editPassword({
                oldPassword: this.editPasswordForm.oldPassword,
                password: this.editPasswordForm.password,
              })
              this.subMitIng = false
              this.editPasswordForm = {
                oldPassword: '',
                password: '',
                confirmpassword: '',
              }
              this.$refs.myDrawerCom.hide()
              setTimeout(() => {
                this.userHandle('logout')
              }, 4000)
            } catch (error) {
              this.subMitIng = false
            }
          } else {
            this.$Message.error('请输入正确的内容!')
            this.subMitIng = false
          }
        })
      }
    },
  },
  watch: {
    $route(newRoute) {
      const { name, query, params, meta } = newRoute
      this.addTag({
        route: {
          name,
          query,
          params,
          meta,
        },
        type: 'push',
      })
      console.log('newRoute:', newRoute)
      this.setTagNavList(getNewTagList(this.tagNavList, newRoute))
      this.activeName = newRoute.name
      this.$nextTick(() => {
        this.$refs.mainMenu.updateActiveName()
        window.scrollTo(0, 0)
      })
    },
  },
  mounted() {
    this.setTagNavList()
    this.setHomeRoute(routers)
    const { name, params, query, meta } = this.$route
    this.addTag({
      route: {
        name,
        params,
        query,
        meta,
      },
    })
    // this.setBreadCrumb(this.$route)
    // 如果当前打开页面不在标签栏中，跳到homeName页
    if (!this.tagNavList.find((item) => item.name === this.$route.name)) {
      this.$router.push({
        name: this.$config.homeName,
      })
    } else {
      this.oldMenuName = this.$route.name
      this.activeName = this.$route.name
      this.$nextTick(() => {
        this.$refs.mainMenu.updateActiveName()
        window.scrollTo(0, 0)
      })
    }

    //定时获取下离日志
    let that = this;
    setInterval(async function () {
      let rst = await optionLogList({"createdDate":dateFormat(new Date(), 'yyyy-mm-dd HH:MM:ss'),"activeFlag":1,"memo":"下线"})
      that.lstLog = rst.data
      that.lstLogSize = rst.data.length
    },1000)
  },
}
</script>
<style lang="scss" scoped>
.layout-main {
  background-image: url('../assets/images/page-bg.jpg');
  background-size: 1920px 1080px;
  background-repeat: no-repeat;
  background-position: top left;
  background-color: #03052c;

  .ivu-layout {
    background-color: transparent;
  }

  .header {
    height: 70px;
    padding: 0;
    background-color: #061a41;
    z-index: 10;
    border-bottom: 1px solid #093154;

    .logo-img {
      display: block;
      width: 500px;
      height: 105px;
      margin-left: 37px;
      margin-right: 67px;
    }

    /deep/ .menu-view {
      /*max-width: 600px;*/
      /*overflow-x: auto;*/
      .ivu-menu {
        background: transparent;

        &::after {
          display: none;
        }

        .ivu-menu-submenu {
          .ivu-menu-submenu-title {
            .ivu-menu-submenu-title-icon {
              display: none;
            }
          }
        }

        .ivu-menu-item,
        .ivu-menu-submenu {
          padding: 0 26px;
          border-bottom: 0;

          &.ivu-menu-item-active,
          &:hover {
            .menu-item {
              .menu-title {
                color: #23aeca;
              }

              .menu-circle {
                opacity: 1;
              }
            }
          }

          .menu-item {
            position: relative;
            display: inline-block;
            padding: 2px 0;
            height: 100%;

            .menu-title {
              font-size: 16px;
              color: #26bad6;
              position: relative;
              z-index: 11;
            }

            .menu-circle {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              bottom: 0;
              opacity: 0;

              .bottom {
                position: absolute;
                width: 100%;
                height: 40px;
                background-color: #00dce0;
                left: 0;
                bottom: -20%;
                border-radius: 100%;
                transform: rotateX(-75deg);
                box-shadow: 0 10px 10px #00dce0;
                z-index: 10;
              }

              .h-box {
                position: absolute;
                left: -10%;
                bottom: 0;
                width: 120%;
                height: 110%;
                background-image: linear-gradient(
                  rgba(0, 81, 105, 0.2),
                  #005169
                );
                box-shadow: 0 0 30px #005169;
                z-index: 9;
                border-bottom-left-radius: 14px;
                border-bottom-right-radius: 14px;
                transform: rotateX(-10deg);
              }
            }
          }
        }
      }
      .ivu-menu-submenu {
        .ivu-select-dropdown {
          padding: 0;
          background-color: rgba(0, 0, 0, 0.7);
          border: 1px solid #092a6e;
          box-shadow: 0 0 20px 6px #090f3b inset;
          .ivu-menu-item {
            padding: 10px 30px;
            color: #9a9bab;
            border-bottom: 1px solid transparent;
            &:hover {
              background-color: transparent;
              color: #26bad6;
              border-bottom: 1px solid #092a6e;
            }
            &:last-child:hover {
              border-bottom: 1px solid transparent;
            }
          }
        }
      }
    }

    .user-info-view {
      color: #2cd6f1;
      margin-right: 37px;

      .iconfont {
        font-size: 18px;
      }

      .user-avatar {
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }

      .user-name {
        cursor: pointer;

        .arrow-down {
          font-size: 16px;
        }
      }
    }
  }

  .content {
    height: calc(100vh - 70px);
    overflow-y: auto;

    &.show-tag {
      height: calc(100vh - 180px);
    }
  }

  .pop-up-box {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);

    .pop-up-content {
      width: 600px;
      height: 670px;
      padding: 30px 35px;
      background-image: url('../assets/images/pop-up-box.png');
      background-repeat: no-repeat;
      background-size: 600px 670px;
      position: relative;

      .popup-close-btn {
        width: 30px;
        height: 30px;
        font-size: 30px;
        position: absolute;
        right: -40px;
        top: -40px;
        color: #197bc6;
      }

      .title-box {
        margin-bottom: 24px;

        .title {
          font-size: 22px;
          color: #fff;
          margin-right: 20px;
        }

        .zebra-box {
          flex: 1;
        }
      }

      .popup-search-box {
        width: 530px;
        height: 50px;
        padding: 0 15px;
        margin-bottom: 14px;
        background-image: url('../assets/images/pop-up-box-search.png');
        background-repeat: no-repeat;
        background-size: 530px 50px;

        .popup-search-input {
          background-color: transparent;
          border: 0;
          outline: none;
          color: #fff;
          font-size: 16px;
          width: 80%;

          &::placeholder {
            color: #233a67;
            font-size: 16px;
          }
        }

        .iconfont {
          color: #1de5e9;
        }
      }

      .results-list-box {
        height: 500px;

        .results-list {
          width: 102%;
          height: 100%;
          padding-right: 2%;
          overflow-y: auto;
        }

        .results-item {
          padding: 14px 0;
          border-bottom: 1px solid #1d1e3b;

          .results-title {
            font-size: 16px;
            color: #fff;
          }

          .to-btn {
            width: 91px;
            height: 30px;
            line-height: 30px;
            background-image: url('../assets/images/tag-bg-on.png');
            background-size: 91px 30px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
