import Layout from '@/layout/Main.vue'


export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      icon: 'md-home'
    },
    component: () => import('@/views/Login.vue')
  },
  // {
  //   path: '/',
  //   name: '_home',
  //   redirect: '/home',
  //   component: Layout,
  //   meta: {
  //     notCache: true
  //   },
  //   children: [
  //     {
  //       path: '/home',
  //       name: 'home',
  //       meta: {
  //         title: '首页',
  //         icon: 'md-home'
  //       },
  //       component: () => import('@/views/Home.vue')
  //     },
  //     {
  //       path: '/exhibitionManagement/:id',
  //       name: 'exhibitionManagement',
  //       meta: {
  //         title: '展厅管理',
  //         icon: 'md-home'
  //       },
  //       component: () => import('@/views/ExhibitionManagement.vue')
  //     },
  //     {
  //       path: '/dataAnalyse/:id',
  //       name: 'dataAnalyse',
  //       meta: {
  //         title: '数据分析',
  //         icon: 'md-home'
  //       },
  //       component: () => import('@/views/DataAnalyse.vue')
  //     },
  //     {
  //       path: '/remoteControl/:id',
  //       name: 'remoteControl',
  //       meta: {
  //         title: '远程控制',
  //         icon: 'md-home'
  //       },
  //       component: () => import('@/views/RemoteControl.vue')
  //     },
  //     {
  //       path: '/realTimePlaying/:id',
  //       name: 'realTimePlaying',
  //       meta: {
  //         title: '实时播放',
  //         icon: 'md-home'
  //       },
  //       component: () => import('@/views/RealTimePlaying.vue')
  //     }
  //   ]
  // },
]
