// 在这里可以做一些通用的配置
import Mock from "mockjs";

// 设置所有ajax请求的超时时间，模拟网络传输耗时
Mock.setup({
  timeout: 0 - 300,
});

const video_pic = require("../assets/images/temp/video-temp.png");

const Random = Mock.Random;

//首页数据
Mock.mock(/\/init_home_data/, "get", {
  code: 0,
  data: {
    total: 100,
    "list|6": [
      {
        "id|+1": 1,
        name: "智慧展厅" + Random.character().toUpperCase() + "楼",
        address: Random.region(),
        theme: Random.csentence(4, 10),
        "total_passenger|1000-10000": 1010,
        "on_line_num|10-30": 10,
        "off_line_num|10-30": 10,
        "video_num|10-30": 10,
        "ppt_num|10-30": 10,
        "excel_num|10-30": 10,
      },
    ],
  },
});

//数据分析
Mock.mock(/\/data_analyse_data/, "get", {
  code: 0,
  data: {
    "resource|12": [
      {
        "date|+1": 1,
        "year|100-300": 110,
        "month|50-100": 60,
      },
    ],
    "device|5": [
      {
        name: "@CNAME",
        "value|50-200": 40,
      },
    ],
    "energy_consumption|12": [
      {
        "date|+1": 1,
        "year|50-500": 50,
        "month|10-100": 14,
      },
    ],
    "passenger|12": [
      {
        "date|+1": 1,
        "year|50-500": 50,
        "month|50-600": 14,
      },
    ],
  },
});

//展厅控制
Mock.mock(/\/remote_control_device_data/, "get", {
  code: 0,
  data: {
    total: 100,
    "list|10": [
      {
        "id|+1": 1,
        name: "@CNAME",
        "state|0-1": 1,
        "property|0-1": 1,
        update: '@date("yyyy.mm.dd HH:mm:ss")',
      },
    ],
  },
});

//媒体控制-媒体文件列表
Mock.mock(/\/remote_control_media_data/, "get", {
  code: 0,
  data: {
    total: 100,
    "list|10": [
      {
        "id|+1": 1,
        name: "@CNAME",
        "state|0-1": 1,
        "type|1": ["视频", "音频"],
      },
    ],
  },
});

//媒体控制-设备列表
Mock.mock(/\/remote_control_device_media_data/, "get", {
  code: 0,
  data: {
    "list|10": [
      {
        "id|+1": 1,
        name: "@CNAME",
        "state|0-1": 1,
        "property|0-1": 1,
        update: '@date("yyyy.mm.dd HH:mm:ss")',
      },
    ],
  },
});

//其他设备
Mock.mock(/\/remote_control_other_device_data/, "get", {
  code: 0,
  data: {
    total: 100,
    "list|10": [
      {
        "id|+1": 1,
        name: "@CNAME",
        "state|0-1": 1,
        "property|0-1": 1,
      },
    ],
  },
});

//实时播放数据
Mock.mock(/\/real_time_playing_data/, "get", {
  code: 0,
  data: {
    "list|6": [
      {
        "id|+1": 1,
        video_name: "@CNAME",
        device_name: "@CNAME",
        video_url: '@url("https", "api.staryjie.com")',
        video_pic: video_pic,
      },
    ],
  },
});

//搜索展厅
Mock.mock(/\/search_exhibition_data/, "get", {
  code: 0,
  data: {
    "list|10": [
      {
        "id|+1": 1,
        name: "@CNAME",
      },
    ],
  },
});

export default Mock;
